
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
:root{
  --dark-gradient: linear-gradient(0deg,#10345E, #3389EF);
  --light-gradient: linear-gradient(0deg, #962734, #FC4A60);
}
h1, h2, h3, h4{
  font-family: 'NeueAachenPro';
}
@font-face {
  font-family: 'NeueAachenPro';
  src: url(/public/fonts/NeueAachenProMedium.TTF);
  font-weight: normal;
  font-style: normal;
} 
body, html{
  font-family: "Inter", sans-serif;
  overflow-x: hidden;
}
a{
  text-decoration: none;
  color: inherit;
}
.lazyload-wrapper {
  display: inherit;
}
.highlights marquee p {
  display: inline-block;
  text-transform: uppercase;
  margin: 0px 10px;
} 
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: url(https://webapi.entab.info/api/image/HTSJ/public/Images/background.gif);
  background-size: 100% auto;
  height: 105px;
}
.campuslogo {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 99;
}

.highlights {
  color: #fff;
  background: #881525;
  width: 95%;
  padding-top: 14px;
}
.highlights i{color: #fff;}
.highlights p{
  margin-bottom: 0;
}
.logo {
  width: 30%;
  padding: 6px;
}

.header-nav {
    width: 69%;
}

nav.navbar {
  background: #ffffffd9;
  padding: 15px;
  width: 95%;
  border-radius: 30px 100px 30px 100px;
  float: left;
  margin-top: 11px;
  position: relative;
  z-index: 9;
}

nav.navbar ul.navbar-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.nav-link{
   color: #333333;
}
nav.navbar ul.navbar-nav li a{
  font-weight: 400;
}
nav.navbar ul.navbar-nav li {text-transform: capitalize;
  font-size: 18px;
  font-weight: 300;
  font-family: 'NeueAachenPro';}
.button{
  display: block;
  margin: 0 auto;
  background: var(--dark-gradient);
    color: #fff;
    border: 0px;
    box-shadow: inset 0 0 7px 0px #3388ed;
    padding: 5px 20px;
    border-radius: 5px;
    text-transform: uppercase;
 
}
.overBox button.button {
  margin: 0;
}
.about-news button.button {
  margin: 0;
}
a:hover{
  color: unset;
}
.eventbg {
  width: 95%;
}

.overBox{
  position: absolute;
  bottom: 17%;
  left: 7%;
  width: 400px;
  padding: 20px 40px;
  border: 1px solid #ddd;
} 
.overBox::before{
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  filter: blur(1px);
  background: #0009;
}
.overBox h2{
  font-weight: 400;
  text-transform: uppercase;
  font-size: 40px;
}

.overBox h2, .overBox p, .overBox button{  position: relative;color: #fff}

.logo img {
  margin: auto;
  display: block;
}

.aboutmidsec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -40px;
  position: relative;
  float: left;
  width: 100%;
}

.aboutmidsec .phone img {
  width: 50px;
}

.aboutmidsec .phone {
  padding: 20px;
  background: #fff;
  box-shadow: inset 0px 0px 9px 0px #adadad;
  border-radius: 20px;
}

.aboutmidsec p {
  margin-bottom: 0;
}

.aboutmidsec .button {
  width: 14%;
  padding: 20px 10px;
  text-align: center;
  box-shadow: 0px 18px 7px -12px #a1a1a1;
  text-transform: uppercase;
}

.padded-sec {
  padding: 0 100px;
}
.about-bg{
  background: url(https://webapi.entab.info/api/image/HTSJ/public/Images/abt-shape1.png);
  background-size: 50% 100%;
  background-repeat: no-repeat;
  padding: 60px 0;
}
.center-img {
  margin: auto;
  display: block;
  position: relative;
  width: max-content;
  padding: 20px;
  z-index: 9;
  width: 58%;
}

.center-img:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: url(https://webapi.entab.info/api/image/HTSJ/public/Images/abt-shape2.png);
  transform: scale(1.0);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.center-img:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: url(https://webapi.entab.info/api/image/HTSJ/public/Images/about-shape3.png);
  transform: scale(1.05);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.center-img img {
  position: relative;
  width: 100%;
}

.title {
  text-transform: uppercase;
  text-align: center;
  color: #10345E;
}

.title p {
  margin-bottom: 2px;
  color: #333333;
}

.about-news {
  width: 80%;
  margin: auto;
  margin-left: auto;
  margin-right: auto;
  background: #ffffffe0;
  padding: 30px 30px 0;
  border-radius: 30px;
  box-shadow: 0px 0px 10px 0px #ababab;
  margin-top: 0%;
}
.classSection {
  font-family: Arial, sans-serif;
  background: url(https://webapi.entab.info/api/image/HTSJ/public/Images/class-bg.png);
  background-size: 100% 50%;
  background-position: bottom;
  background-repeat: no-repeat;
  padding: 30px 0;
  margin: 80px 0;
}

.tabs {
  display: flex;
  cursor: pointer;
}

.tab {
  padding: 10px 20px; 
  border-bottom: none; 
  margin-right: 5px; 
  width: 17%;
  position: relative;
} 

.tabs-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 9;
    border-radius: 50%;
}

.tabs-img {
    width: 150px;
    margin: 20px auto;
    border-radius: 50%;
    height: 150px; 
    position: relative;
}
.tabs-img > div{
  height: inherit;
}
.tab-title {
  text-align: center;
  color: #10345E;
  font-family: 'NeueAachenPro';
  font-size: 22px;
  z-index: 9;
  position: relative;

}


.tabs-content {
  padding: 30px;
  margin: 20px 0;
  background: #eee;
  border-radius: 25px;
  text-align: center;
  width: 93%;
  margin: 20px auto;
  box-shadow: 0px 20px 15px -25px #000;
}
.tab:after {
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translate(-50%);
  content: "";
  width: 0;
  height: 0;
  border-bottom: 20px solid #eeeeee;
  border-left: 30px solid #0000;
  border-right: 30px solid transparent;
  transition: 0.5s;
  opacity: 0;
}
.tab.active:after {top: 100%;opacity: 1;}
.content p {
  margin: 0;
} 

.tabs-content button.button { 
    position: relative;
    bottom: -42px;
    position: relative;
}

.content {
    position: relative;
} 
.header-text h3 {
     color: #0788B1;  
    text-align: center;
    padding: 20px 0px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 1px; 
}
.calender{
  position: relative;
}
.header-text h5 {
  display: none;
  font-size: 16px;
  text-align: center;
  color: #333;
  margin: 10px;
}
.calender_block{
  background: url(https://webapi.entab.info/api/image/HTSJ/public/Images/calender-bg.png);
  background-size: 100% 100%;
  padding: 30px;
  box-shadow: 14px 20px 40px -40px #000;
} 

.calendar-header i {
  font-size: 47px !important;
  padding: 0px 10px;
}
.copyright a {
  color: #f11e13;
}
.error {
  color: red;
  font-size: 12px;
}
.formdiv span ,.careerform-feilds span{
  color: red;
}
.calendar-footer h4 {
  margin: 20px auto;
} 
.blue {
  color: #0788B1;
  font-weight: bold;
}

.sidebar-title {
  padding: 10px;
  display: none;
}
 
.calendar-wrapper.z-depth-2 {
  padding: 5px;
}
.event-mark {
  width: 5px;
  height: 5px;
  background-color: teal;
  border-radius: 100px;
  position: absolute;
  left: 46%;
  top: 70%;
}

.calendar-footer {
  font-family:"Inter", sans-serif;
  font-weight: 300;   
  position: relative;
  overflow: hidden;
  padding: 27px 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; 
}
.border-blk{
  border: 1px solid #b2fb93;
}
.calendar-wrapper {
  display: flex; 
  width: 90%;
  margin: auto;
  align-items: center;
}

.content-wrapper {
  width: 100%;
}

.main-wrapper {
  width: 100%;
}

.calendar-wrapper>div {
  width: 50%;
}
 

.calendar-wrapper>div:last-child {
  width: 40%;
  margin-left: auto;
  background: url(https://webapi.entab.info/api/image/HTSJ/public/Images/cal_shape.png);
  background-size: 100% 100%;
  padding: 20px;
  height: max-content;
} 
.calendar-wrapper .emptyForm {
  height: 300px;
  overflow-y: scroll;
  margin-bottom: 40px;
  padding-left: 30px;
}
.calendar-footer h4 {
  margin: 20px auto;
}
 
.calendar-header button.prev-button ,.calendar-header button.next-button {
  display: none;
}
.eventlist p {
  margin: 0px 9px;
}

 
.sidebar-wrapper {
  color: #fff;
  background-color: #5a649c !important;
  font-family:"Inter", sans-serif;
  font-weight: 300;
  padding: 0 0 20px;
}

.eventCard {
  background-color: #fff;
  color: #000;
  padding: 12px 24px;
  border-bottom: 1px solid #E5E5E5;
  white-space: nowrap;
  position: relative;
  animation: slideInDown .5s;
}

.eventCard-header {
  font-weight: 700;
}

.eventCard-description {
  color: grey;
}

.eventCard-mark-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 60px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 25%, white 100%);
}

.eventCard-mark {
  width: 8px;
  height: 8px;
  background-color: #b39ddb;
  border-radius: 100px;
  position: absolute;
  left: 50%;
  top: 45%;
}

#table-body .row .col {
  padding: .6rem;
  width: 14%;
}

#table-body .row {
  margin-bottom: 0;
}

#calendar-table {
  text-align: center;
}

.prev-button {
  position: absolute;
  cursor: pointer;
  left: 0;
  color: #333 !important;
}

.prev-button i {
  font-size: 4em;
}

.next-button {
  position: absolute;
  cursor: pointer;
  right: 0;
  color: #333 !important;
}

.next-button i {
  font-size: 4em;
}

#table-body .col {
  max-width: 13%;
  min-width: 14%;
  padding: 12px 0;
  text-align: center;
}

div#emptyForm span {
  color: #0788B1;
  min-width: 28px;
  display: inline-block;
  font-weight: 600;
}

div#emptyForm p {
  margin-bottom: 16px;
}
.message-container {
  display: flex;
}

.message-container .messagebox {
  width: 80%;
  background: url(https://webapi.entab.info/api/image/HTSJ/public/Images/message-bg.png);
  background-size: 100% 100%;
  padding: 70px;
  margin-right: 20px;
}

.message-container .messageimg {
  width: 20%;
  margin-top: 80px;
}
.event-cal {
  margin-top: 50px;
}
.event {
  padding: 100px 0;
  position: relative;
}
.message-container .messageimg .mainbtn.button {
  width: max-content;
  margin: auto;
}
.messageboximg p span{
  display: block;
  color: #333;
}
.messageboximg {
  text-transform: uppercase;
  color: #1a4a83;
}
.message-container .messageimg  img {  margin: auto;
  display: block;
  width: 100%;
  height: 243px; 
  margin-bottom: 20px;}

  .testimonialimg{

    position: relative;
  }
  .testimonialimg:before {
    position: absolute;
    bottom: -15px;
    left: 20px;
    content: ' ';
    background-size: contain;
    height: 100%;
    width: 100%;
    z-index: -1;
    background: url(https://webapi.entab.info/api/image/HTSJ/public/Images/principalbg.png) no-repeat center bottom;
 
}
.topper_bg{
  background: url(https://webapi.entab.info/api/image/HTSJ/public/Images/topper_bg.png);
  background-position: bottom;
  background-size: 100% auto;
  padding: 80px 100px;
  background-repeat: no-repeat;
}
.toppertaj{
  background: url(https://webapi.entab.info/api/image/HTSJ/public/Images/topper-shape.png);
  background-size: 100% 100%;
  padding: 24px 10px;
  width: 80%;  
  margin: 20px auto;
}
.topper-block {
  width: 90%;
  margin: 40px 0
}
.wid-100{
  width: 100%;
  margin-top: -5px
}
.toppertaj img {
  width: 130px;
  height: 130px;
  object-fit: contain;
  border-radius: 50%;
  margin: auto;
}
.toppertaj img {
  width: 130px;
  height: 130px;
  object-fit: contain;
  border-radius: 50%;
  margin: auto;
}

.topperdiv {
  text-align: center;
}

.topperdiv .topperdesc p.name {
  text-transform: uppercase;
  color: #000;
  margin-bottom: 6px;
}

.topperdiv .topperdesc p.per {
  font-weight: 800;
  color: #18467d;
  font-size: 20px;
}

.galleryimgbox {
  position: relative;
  border: 6px solid #fff;
  box-shadow: 2px 20px 20px -18px #000;
  width: 96%;
  margin: 25px 0;
  overflow: hidden;
}

.galleryimgbox .gallerydesc {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 1s;
}
.galleryimgbox:hover .gallerydesc {
  opacity: 1;
} 

.homegallery > div {
    padding: 80px 0;
    text-align: center;
}

.galleryimgbox h6 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  background: #0000008f;
  color: #fff;
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
  width: 100%;
  text-align: center;
  padding: 6px;
  font-size: 17px;
  line-height: 30px;
}

a.gallery_button, .dark-button {
  background: #10345E;
  color: #fff;
  padding: 8px 19px;
  border-radius: 20px;
  border: 0px;
  text-transform: uppercase;
}
.facebook_title h3 {
  color: #fff;
  background: #10345E;
  text-align: center;
  padding: 6px;
}
.facebook{
  background: url(https://webapi.entab.info/api/image/HTSJ/public/Images/facebook-bg.png);
  background-size: 100% 100%;
}
.facebook_title{
  margin: 50px 0
}
.facebook_title p {
  background: #EEEEEE;
  text-align: center;
  padding: 10px 10px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 20px 16px -24px #000;
  border: 1px solid #c6fdc9;
}
.footer{
  background: url(https://webapi.entab.info/api/image/HTSJ/public/Images/footer-bg.png);
  background-size: 100% 100%;
  padding: 100px 80px 10px;
  position: relative;
} 
.copyright{
  text-align: center;
}
.footer .ftrShape1{ 
  height: 80px;
  background: url(https://webapi.entab.info/api/image/HTSJ/public/Images/footer-bg.png);
  animation: 2s ftrshape1 infinite;
  width: 100%;
  position: absolute;
  top: -14px;
  left: 0;
  background-size: 100% 100%;
}
@keyframes ftrshape1{
  0%{
    background: url(https://webapi.entab.info/api/image/HTSJ/public/Images/ft-shape1.png);
    background-size: 100% 100%;
  }
  50%{
    background: url(https://webapi.entab.info/api/image/HTSJ/public/Images/ft-shape2.png);
    background-size: 100% 100%;
  }
  100%{
    background: url(https://webapi.entab.info/api/image/HTSJ/public/Images/ft-shape3.png);
    background-size: 100% 100%;
  }
}
.ftr-logo > div {
  width: 70%;
  margin-left: auto;
}

.quick-link ul {
  display: flex;
  flex-direction: column;
  height: 170px;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}

.quick-link ul li {
  margin-bottom: 7px;
  font-size: 14px;
}

.address p {
  display: flex;
}
.address p i{
  margin-right: 10px;
}
.ftr-logo img {
  width: auto;
  margin-bottom: 10px;
  margin: auto;
  display: block;
}

.footer iframe {
     height: 220px;
     border: 6px solid #fff;
     box-shadow: 0px 0px 5px 0px #ddd;
     }
     ul.socialMedia {
      list-style: none;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
  }
  
  ul.socialMedia li {
      margin: 5px;
  }

  .homeeventboximg p {
    width: 100%;
    position: absolute;
    top: 10px;
    left: 0; 
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp:1;
    overflow: hidden; 
}
.homeeventboximg p span div img {
    width: 35px !important;
    height: auto !important;
}
.homeeventboximg .lazyload-wrapper img {
  object-fit: contain;
  height: 200px;
  width: 100%;
}
.homeeventboximg {
    position: relative;
}
.msginfo>div {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  overflow: hidden;
  margin-bottom: 20px;
}
.homeeventboximg p span {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    max-width: 90%;
    font-size: 12px;
    width: max-content;
    padding: 0px 10px;
    border-radius: 21px; 
}

.homeeventbox {
    width: 95%;
    border-radius: 15px;
    overflow: hidden;
    background: #EEEEEE;
    border: 1px solid #b2ffeb;
    margin: 40px 0;
}

.homeeventbox .homeeventboxdesc {
    text-align: center;
    padding: 10px;
}

.homeeventboximg > img.img-fluid {
    height: 190px;
    width: 100%;
    object-fit: cover;
}

.homeeventboxtitle h3 {
    text-transform: uppercase;
    font-size: 19px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp:1;
    overflow: hidden; 
}

.homeeventboxtitle {
    margin-top: 10px;
}

.homeeventboxtitle p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp:1;
    overflow: hidden; 
}

.homeeventboxbottom {
    background: #10345E;
    border-radius: 15px;
    color: #fff;
    padding: 5px;
    text-transform: uppercase;
    font-size: 13px;
}
.overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000b0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.5s;
}
.homeeventbox:hover .overlay{
  opacity: 1;
}

.abt_abs {
  position: absolute;
  border-radius: 50%;
}

.abt_abs.abs-img1 {
  left: 20px;
  top: 50px;
}
.abt_abs.abs-img1::before {
  background: #FFA9A9;
  box-shadow: 0px 0px 0px 1px #ffa9a9, inset 0px 0px 0px 6px #fff;
}
.abt_abs.abs-img2::before {
  background: #CE8FFF;
  box-shadow: 0px 0px 0px 1px #CE8FFF, inset 0px 0px 0px 6px #fff;
}
.abt_abs.abs-img3::before {
  background: #8F8CFF;
  box-shadow: 0px 0px 0px 1px #8F8CFF, inset 0px 0px 0px 6px #fff;
}
.abt_abs.abs-img4::before {
  background:#FFA96A;
  box-shadow: 0px 0px 0px 1px #FFA96A, inset 0px 0px 0px 6px #fff;
}
.abt_abs.abs-img2 {
  right: 20px;
  top: 20px
}

.about-img {
  padding: 90px 0 90px;
  position: relative;
  overflow: hidden;
}

.abt_abs.abs-img4 {
  left: 0;
  bottom: 20px;
}

.abt_abs.abs-img5 {
  right: 0;
  bottom: 20px;
}

.abt_abs img {
  border-radius: 50%;
  position: relative;
  z-index: 9
}

.abt_abs.abs-img4 img {
  width: 160px;
}
.event:before {
  content: "";
  width: 320px;
  height: 330px;
  background: linear-gradient(45deg, #bef843, #58ffeb);
  position: absolute;
  left: -220px;
  top: 0;
  border-radius: 110px;
  transform: rotate(45deg);
  animation: 20s rotate infinite;
}
@keyframes rotate{
  0%{
      transform: rotate(0deg)
  }
   100%{
      transform: rotate(360deg)
  }
}
.event:after {
  content: "";
  width: 320px;
  height: 330px;
  background: linear-gradient(45deg, #bef843, #58ffeb);
  position: absolute;
  left: -200px;
  top: -50px;
  border-radius: 110px;
  transform: rotate(45deg);
  opacity: 0.2;
  animation: 40s rotate infinite;
}

.calender:before {
  content: "";
  width: 320px;
  height: 330px;
  background: linear-gradient(45deg, #bef843, #58ffeb);
  position: absolute;
  right: -220px;
  bottom: 0;
  border-radius: 110px;
  transform: rotate(45deg);
  animation: 20s rotate infinite;
}
@keyframes rotate{
  0%{
      transform: rotate(0deg)
  }
   100%{
      transform: rotate(360deg)
  }
}
.calender:after {
  content: "";
  width: 320px;
  height: 330px;
  background: linear-gradient(45deg, #bef843, #58ffeb);
  position: absolute;
  right: -200px;
  bottom: -50px;
  border-radius: 110px;
  transform: rotate(45deg);
  opacity: 0.2;
  animation: 40s rotate infinite;
}

.tabs-img:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  background: #18477d;
  border-radius: 50%;
  transform: scale(1.15);
  transform-origin: center;
  box-shadow: 0px 0px 0px 1px #18477d, inset -0px 0px 0px 6px #fff;
}

.tabs-img:after {
  content: "";
  width: 120%;
  height: 130%;
  position: absolute;
  left: 9px;
  top: -48px;
  background: #fff;
  transform: rotate(45deg) scale(1);
  /* border-radius: 50%; */
  transition: 0.5s; 
}

.tabs-img:hover:after {
  transform:  rotate(45deg) scale(0);
}



.abt_abs:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  background: #18477d;
  border-radius: 50%;
  transform: scale(1.15);
  transform-origin: center;
  box-shadow: 0px 0px 0px 1px #18477d, inset -0px 0px 0px 6px #fff;
}

.abt_abs:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background: linear-gradient(45deg, white 70%, transparent 20%);
  transform: rotate(45deg) scale(1);
  /* border-radius: 50%; */
  transition: 0.5s;
  animation: 5s rotate1 infinite;
  transform-origin: center;
}
@keyframes rotate1{
  0%{
    transform: rotate(0deg) scale(1.2);
    transform-origin: center
  }
  100%{
    transform: rotate(360deg) scale(1.2);
    transform-origin: center
  }
}
.about-news button.button {
  position: relative;
  bottom: -17px;
  left: 50%;
  transform: translate(-50%);
}
.slider-sec{
  position: relative;
} 
.copyright {
  text-align: center;
  font-size: 13px;
  padding: 10px;
}

.innerslide {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(360deg, #1c1b3b8f 100%, #1c1b3b91 100%, #3333338c 100%),url('https://webapi.entab.info/api/image/HTSJ/public/Images/innersld.JPG') no-repeat center center;
  background-size: cover;
  height: 255px;
  padding-bottom: 50px;
}
.innersec {
  min-height: 400px;
  padding: 60px 10px 80px 10px;
}
.innersec p {
  line-height: 34px;
  font-size: 16px;
  color: #4e4e4e; 
}
.innersec h1 {
  z-index: -1;
  font-size: 35px;
  position: relative;
  padding-left: 70px;
  background:-webkit-linear-gradient(#474879, #a31717);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 45px;
  padding-bottom: 20px;
} 
.innersec h1:after {
  position: absolute;
  content:  ' ';
  width: 60px;
  height: 3px;
  background:-webkit-linear-gradient(#474879, #a31717);
  top: 20px;
  bottom: 0;
  left: 0;
}

.breadcrumb li:before {
  position: absolute;
  content: ' \F138 ';
  width: 100%;
  height: 100%;
  font-family: bootstrap-icons;
  left: -15px;
}
.breadcrumb li:first-child:before {
  content: "\f404";
  background: #ef3436;
  width: 30px;
  border-radius: 20px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -25px;
  top: -3px;
}
.breadcrumb li a {
  color: #f9f9f9;
  position: relative;
}
.breadcrumb li {
  padding: 0px 15px 0px 15px;
  color: #fff;
  position: relative;
  margin-left: 7px;
  font-size: 14px;
}
ul.breadcrumb {
  margin-bottom: 0;
}

#myImg, #myImg1, #myImg2 {
  width: 100%;
  border: 5px solid rgba(158, 158, 158, 0.1);
  background: #eaeaea;
  cursor: pointer;
  transition: 0.3s;
}
.erp_sec img { 
  display: inline-block;
}
.erp_sec h4.imgheading {
  text-align: center;
}
.assistance {
  background: #eaeeff;
  padding: 20px;
  margin-top: 30px;
}
.erp_sec h4 {
  text-align: center;
  line-height: 40px;
  font-size: 17px;
  margin-bottom: 0;
  margin-top: 15px;
  font-weight: 700;
  font-family:"Inter", sans-serif;
}

.assistance {
  background:rgb(230 243 255);
  padding: 13px;
  border: solid 1px #ddd;
  margin-top: 20px;
}
.erpbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.erpboxdesc {
  width: 72%;
}

.erpboximg {
  width: 26%;
}

.erpboxdescpopup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
 
.erppopup {
  width: 30%;
  margin: 10px;
}

.imgheading a { 
  text-align: center;
  width: 100%;
  display: block;
  font-size: 20px;
  padding: 10px 0;
}

.erppopup .modal-body h5 {
  color: #000;
  text-align: center;
  padding-top: 20px;
}

.chooseyear {
  width: max-content;
  float: right;
  margin-bottom: 30px;
  margin-top: -45px;
}
    #chooseddlYear option { color: black;}
    .chooseyear  select{margin:0px;}
    .chooseyear  select{width:100%;background: unset;border: unset;height:28px; margin-top:15px;border: unset;border-bottom: 1px solid grey;}
    .clr{clear:both} 
    .video-gallery {
      padding: 20px;
      box-shadow: 0px 1px 4px 1px #03030330;
      margin-bottom: 20px;
      background: #f1f1f1;
      border-radius: 3px;
  }
    .video-gallery a {
      display: flex;
      color: inherit;
      align-items: center;
    }
    .video-gallery a i {
     font-size: 40px;
     color: #ad0807;
     margin-right: 10px;
     display: flex;
    }
    .video-gallery a h3 {
      font-size: 16px;
      color: #333;
      margin-bottom: 0;
      line-height: 24px;
      padding-top: 0;
      padding-bottom: 0;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin-top: 0;
      padding-left: 0;
  }
    .video-gallery p {
      font-size: 12px;
      margin-bottom: 0;
    }
    .video-gallery a h3:after {
      position: static;
    }
    .video-gallery a img {
      width: 52px;
      margin-right: 10px;
    }
    
.row.tabs-dynamic {
  background: #f1f1f1;
  padding: 20px;
  margin: 0px 0px 40px 0px;
}
.row.tabs-dynamic p {
  margin-bottom: 0;
}
.row.tabs-dynamic p a {
  color: #585757;
}
.row.tabs-dynamic select {
  width: 100%;
  border: 0px;
  background: transparent;
  padding: 8px 5px;
  border-bottom: 1px solid #b6b6b6;
}
.row.tabs-dynamic input {
  padding: 9px 5px;
  width: 100%;
  border: 0px;
  border-bottom: 1px solid #b6b6b6;
  background: transparent;
}
.count-val p {
  margin-bottom: 0px;
}

.gallery-inner {
  background:linear-gradient(88deg, #f1f1f1, #f1f1f1);
  margin-bottom: 30px;
  box-shadow: 7px 6px 12px -4px #ddd;
  /* position: relative; */
  overflow: hidden;
  transition: all 0.5s ease-in;
}
.gallery-blk a {
  color: inherit;
}
.gallery-content {
  padding: 20px 15px;
  position: relative;
  background: #fff;
  width: 96%;
  margin: -30px auto 10px auto;
}

.gallery-blk img {
  width: auto;
  max-width: 100%;
  height: 90%;
  margin: auto;
  display: block;
  position: relative;
  box-shadow: 0px 0px 19px 0px #00000054;
  border: 7px solid #ffffff4f;
}

.gallery-inner:hover .gallery-content {
  bottom: 0;
}
.gallery-blk {
  height: 230px;
  object-fit: contain;
  position: relative;
  background: #ddd;
  overflow: hidden;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub-gallerycount .gallery-blk img {
  height: 100%;
}
.sub-gallerycount .gallery-blk {
  height: 200px;
  margin-bottom: 20px;
}
.gallery-blk .gallery-title h3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.gallery-title h3 {
  font-size: 18px !important;
  color: #333!important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  padding: 0px;
  margin-bottom: 15px;
  font-family: "Inter", sans-serif;
}
a.view-more {
  color: var(--black);
  font-size: 14px;
  font-family: "Inter", sans-serif;
}
.gallery-title p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: 28px !important;
  font-size: 16px !important;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}
.gallery-date {
  display: flex;
}

.gallery-date h6 {
  color: #fff;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 25px;
  background: #b72c2c;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: "Inter", sans-serif;
}
.gall-num h5 {
  background: transparent;
  color: #000;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 25px;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: "Inter", sans-serif;
}
.gallery-title {
  font-size: 20px;
  line-height: 30px;
  font-family: "Inter", sans-serif;
} 
.sub-gallerycount img {
  height: 200px;
  width: 100%;
  object-fit: cover;
  background: #f1f1;
  box-shadow: 0px 0px 3px 1px #0000004a;
}
.sub-gallerycount { 
  margin-bottom: 20px;
}

.news-head {display: flex;align-items: center;}
.news-head h2 {    padding-right: 20px; border-right: 1px solid #c5c5c5;  margin-right: 20px;  background: #e5e5e5; padding: 4px 10px;}
.news-head h3 {
    font-weight: 400;
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 14px;
    color: #00599b;
    position: relative;
}
.news-blk {margin-bottom: 20px;padding-right: 30px;padding-bottom: 30px;width: 97%;box-shadow: 9px 11px 5px 0px #dddddd3d;position: relative;overflow: hidden;padding: 20px;box-shadow: 0px 0px 12px 0px #e7e7e7;border: 4px double #b5b5b5;transition: 0.5s;}
.news-blk:before {
    content: "";
    position: absolute;
    top: -40px;
    right: -8px;
    width: 100px;
    height: 100px;
    background: #ecf9ff;
    border-radius: 50%;
}
.attachment {
    background: #494b7d;
    text-align: right;
    position: absolute;
    right: 0;
    bottom: 0;
    color: #fff;
    padding: 5px;
    }
.news-content p {margin-bottom: 6px;max-height: 160px;overflow-y: scroll;}
.news-content p::-webkit-scrollbar {width: 0;}
.news-blk:hover {
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 1px, rgb(0 0 0 / 5%) 0px 4px 2px, rgb(0 0 0 / 5%) 0px 8px 4px, rgb(0 0 0 / 5%) 0px 16px 8px, rgb(0 0 0 / 5%) 0px 32px 16px;
}
.news-head h2 span {
    display: block;
    color: #153168;
    font-size: 18px;
    text-align: center;
}
.newsCount:nth-child(4n+1) > div {
    border-left: 3px solid #fff27e;
}
.newsCount:nth-child(4n+2) > div {
    border-left: 3px solid #ceb2ff;
}
.newsCount:nth-child(4n+3) > div {
    border-left: 3px solid #ff928f;
}
.newsCount:nth-child(4n+4) > div {
    border-left: 3px solid #ffb470;
}

.eventsnewsbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #fff;
  box-shadow: 2px 0px 8px 0px #ccc;
  margin-bottom: 20px;
}

.newsboximg {
  width: 40%;
  margin-right: 10px;
}

.newsboxdesc {
  width: 58%;
}
.newspgh {
  height: 150px;
  overflow-y: scroll;
  padding-top: 15px;
  margin-top: 10px;
  padding-right: 10px;
}
.uniformimg {
  height: auto;
  margin-bottom: 20px;
}
.newsboximg img {
  border: 10px solid #fff;
  height: 190px;
  object-fit: contain;
  background: #f1f1f1;
  width: 100%;
}
.newsdate {
  display: flex;
  justify-content: space-between;
  padding: 8px 5px;
}
.newspgh p {
  font-size: 15px;
  line-height: 30px;
}
.newsdate a {
  background: #881525;
  border-radius: 5px 3px 6px 0px;
  color: #fff;
  padding: 2px 5px;
}
.newsdate span {
  font-size: 14px;
  line-height: 30px;
}
.event-logo {
  height: 320px!important;
}
i {
  position: relative;
  top: -2px;
}
.newspgh h5 {
  font-family:"Inter", sans-serif;
  color: #000000ad;
  line-height: 30px;
  font-size: 18px;
}
.newspgh::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.newspgh::-webkit-scrollbar-thumb {
  background-color: var(--maroon);
}
.newspgh::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #F5F5F5;
}
.gallery-blks img {
  height: 230px;
  width: 100%;
  object-fit: cover;
}
.toppers-inner {
  padding: 10px;
  min-height: 308px;
  margin-bottom: 20px;
  background: #42437024;
  border-radius: 3px;
  box-shadow: inset 0px 0px 13px 5px #3d3e6857;
}
.toppers-inner img {
  margin: auto;
  width: 150px;
  height: 140px;
  border-radius: 3px;
  margin-top: 20px;
  display: block;
  border: 6px solid #fff;
} 
.toppers-inner  h3{    color: #333!important;
  text-align: center;
  font-size: 16px !important;
  line-height: 25px !important;
  margin: 0px;
  margin-top: 10px;} 
.toppers-inner  p{text-align:center;margin-bottom: 0;}
.toppers-inner  h4{font-size: 15px;text-align: center;} 
.toppers-inner  h5{    margin: 0px;
  color: #ac2121;
  font-size: 15px;
  text-align: center;
  line-height: 25px;} 
  .magnify-modal {
    position: fixed !important;
    z-index: 999999999 !important;
    padding-top: 10px !important;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    height: 100% !important;
    overflow: auto !important;
    background-color: rgba(0, 0, 0, 0.8509803922) !important
  }
  
  .magnify-header .magnify-toolbar {
    float: right;
    position: relative;
    z-index: 9
  }
  
  .magnify-stage {
    position: absolute;
    top: 40px;
    right: 10px;
    bottom: 40px;
    left: 10px;
    z-index: 1;
    background: #0000000a;
    overflow: hidden
  }
  
  .magnify-image {
    position: relative;
    display: inline-block;
    border: 5px solid #fff
  }
  
  .magnify-image.image-ready {
    max-width: 100%;
    max-height: 100%
  }
  
  .magnify-footer {
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 40px;
    color: #fff;
    text-align: center
  }
  
  .magnify-footer .magnify-toolbar {
    display: inline-block
  }
  
  .magnify-button {
    display: inline-block;
    width: 40px;
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    border-width: 0;
    border-radius: 0;
    cursor: pointer;
    outline: none;
    background: #055a91;
  }
  
  .magnify-button:hover {
    color: #fff;
    background-color: #e00000
  }
  
  .magnify-button-close:hover {
    background-color: #e00000
  }
  
  .magnify-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.3);
    color: #333
  }
  
  .magnify-loader::before {
    content: '';
    display: inline-block;
    position: relative;
    width: 36px;
    height: 36px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    -webkit-animation: magnifyLoading 1s infinite linear;
    animation: magnifyLoading 1s infinite linear
  }
  
  .magnify-loader::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    overflow: hidden
  }
  .tcblock {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  } .tc {
    width: 49%;
    margin-bottom: 30px;
  } .tcblock iframe {
    width: 49%;
  } .tc label {
    color: #000;
    width: 100%;
    margin-bottom: 0;
  } .tc label p {
    margin-bottom: 0px;
  } .tc .form-group {
    margin-bottom: 20px;
    width: 100%;
  } .form-control:focus {
    box-shadow: none;
    border: 1px solid #ccc;
  } a.viewdetailsbtn {
    color: #fff;
    font-size: 14px;
  } .viewdetailsbtn {
    padding: 10px 15px;
    text-decoration: none;
    color: #fff;
    border-radius: 5px;
    margin-bottom: 20px;
    border: none;
    background:linear-gradient(131deg, #9c1010, #38395f);
    transition: all 0.2s ease-in;
  } .viewdetailsbtn:hover {
    background:linear-gradient(131deg, #40416d, #950909);
  } 
  
  .mesdeskimg h3 {
    color: #e55e12;
    margin-bottom: 8px;
    font-size: 18px;
    margin-top: 30px;
    line-height: 30px;
  }
  .left-img {
    border: 10px solid #fff;
    box-shadow: 1px 5px 8px 0px #ccc;
    border-radius: 4px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  @-webkit-keyframes magnifyLoading {
    0% {
        -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
        transform: rotateZ(0deg) translate3d(0, 0, 0)
    }
  
    100% {
        -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
        transform: rotateZ(360deg) translate3d(0, 0, 0)
    }
  }
  
  @keyframes magnifyLoading {
    0% {
        -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
        transform: rotateZ(0deg) translate3d(0, 0, 0)
    }
  
    100% {
        -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
        transform: rotateZ(360deg) translate3d(0, 0, 0)
    }
  }
  .newsdate h2 {
    font-size: 14px;
    line-height: 30px;
    font-family: "Inter", sans-serif;
    margin-bottom: 0;
}
.newspgh h3 {
  font-family: "Inter", sans-serif;
  color: #333 !important;
  line-height: 30px !important;
  font-size: 18px !important;
}
.mesdeskimg h5 {
  font-size: 14px;
  color: #333;
}
.mainbox {
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  flex-wrap: wrap;
}

.mainbox p {
  width: 100%;
  background: #ffa39f1f;
  padding: 10px 8px;
  border-left: 5px solid #843b2e;
  margin: 0px 10px 15px 0px;
  box-shadow: 0px 3px 3px 1px #ccc;
  border-radius: 3px;
}
.innersec h3 {
  color: #84312e;
  font-size: 23px;
  line-height: 30px;
}
ul.list li {
  width: calc(49% - 40px);
  position: relative;
  list-style: none;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  line-height: 34px;
  color: #4e4e4e;
  margin-right: 35px;
}
ul.list {
  display: flex;
  flex-wrap: wrap;
}
ul.list li:after {
  color: #1ca382;
  content: "\2726";
  font-size: 1.6em;
  left: -30px;
  position: absolute;
  top: 0;
}
.studentcouncil table {
  padding: 140px;
  border: 3px double #000;
  font-size: 14px;
  border-radius: 4px;
}

.studentcouncil tr:nth-child(even) {
  background: #f1f1f121;
}
.studentcouncil tr:nth-child(odd) {
  background: #d5586926;
}
.smartclass {
  padding: 5px;
  margin-bottom: 20px;
  border: 5px double #ca40406b;
  border-radius: 5px;
}
ul.list.newlist li{
  width: 100%;
}
.htsfamily p {
  margin-bottom: 0px;
  font-size: 15px;
  border-bottom: 1px dashed #ccc;
}

.htsfamily h3 {
  margin-top: 20px;
}
.blogboxdesc {
  width: 100%;
}
.blogbox {
  background: #f1f1f1;
  display: flex;
  border-radius: 5px; 
  margin-bottom: 20px;
  padding:0px 0px 0px 20px;
}

.blogboximg {
  margin-top: 20px;
  margin-right:  10px;
  margin-bottom: 40px;
  width:calc(22% - 20px);
  height: 200px;
  border-radius: 50%;
}
.blogboxdesc{
  height: 220px;
  overflow-y: scroll;
  padding-left: 20px;
}
.blogboxdesc h6 {
  padding-top: 30px;
}
.blogboximg img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 5px solid #ccc;
}
.blogboxdesc h6 {
  padding-top: 30px;
}

p.blogname span {
  display: block;
  color: #865454;
  font-size: 15px;
}

p.blogname {
  margin-bottom: 0;
}

p.blogdate {
  margin-bottom: 0;
  font-size: 13px;
}
 
.blogboxdesc::-webkit-scrollbar {
  width: 8px;
}
.blogboxdesc::-webkit-scrollbar-thumb {
  background: #881525;
  border-radius: 10px;
}
.blogboxdesc::-webkit-scrollbar-thumb {
  cursor: pointer;
  height: 25%;
  -webkit-transition: .5s ease;
  transition: .5s ease;
  width: 0;
}
.blogboxdesc::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 5px grey;
}
.blogboxdesc::-webkit-scrollbar-track {
  background: #fff;
  border-left: 0 solid #fff;
  border-right: 0 solid #fff;
  width: 11px;
}
.admissioninfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
.admissionbox {
  width: calc(33% - 20px);
  text-align: center;
  background: #fff;
  padding: 10px 15px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 9px 1px #0603034a;
  border: 1px dashed #055a91;
  position: relative;
  overflow: hidden;
}
.admissionbox:before {
  content: "";
  position: absolute;
  top: 0;
  right: -40px;
  height: 100%;
  width: 100px;
  background: #e5eff3;
  border-radius: 50%;
}
.admissionbox img {
  width: 50px;
  float: left;
  margin-right: 20px;
  margin-top: 15px;
  margin-bottom: 25px;
}
.admissionbox p {
  font-size: 17px !IMPORTANT;
  color: #055a91 !important;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  margin-bottom: 0 !IMPORTANT;
}
.admissionbox a {
  font-weight: bold;
  color: #000;
  text-align: left;
  display: flex;
}
.formbox {
  flex-wrap: wrap;
  background: #fff;
  box-shadow: 0 30px 50px rgb(0 0 0 / 10%);
  display: flex;
  margin-bottom: 60px;
  border-radius: 4px;
}
.formboxright {
  width: 70%;
  padding: 50px 20px 50px 20px;
}

.formboxleft {
  width: 30%;
  background: url('https://webapi.entab.info/api/image/HTSJ/public/Images/form-bg.png') no-repeat center bottom;
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
}
.addressimg {
  float: left;
  width: 45px;
  height: 45px;
  background: #fff;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 10px;
}

.addressdesc a {
  color: #fff;
  font-size: 14px;
  line-height: 33px;
  font-family: 'Poppins', sans-serif;
  word-break: break-all;
}

.addressdesc {
  margin-bottom: 20px;
  clear: both;
}
.form-group {
  margin-bottom: 15px;
  width: 48%;
  margin-right: 17px;
}
.formdiv {
  display: flex;
  flex-wrap: wrap;
}
.form-group label {
  margin-bottom: 10px;
}
.formboxleft h5 {
  font-size: 30px;
  color: #fff;
  font-family: revert;
  margin-bottom: 30px;
  text-align: center;
  line-height: 40px;
}
input[type="submit"] {
  transition: all 0.3s ease-in;
  padding: 10px 30px;
  border: 0px solid #ccc;
  background: linear-gradient(45deg, #5d1010, #be973e);
  color: #fff;
  border-radius: 4px;
  margin-top: 10px;
}
.form-group.textarea {
  width: 100%;
}
 
.form-group.textarea textarea {
  resize: none;
}
.glancebox {
  display: flex; 
  margin-top: 30px;
  margin-bottom: 20px;
}

.glancediv {
  width: calc(36% - 30px); 
}

.glanceno p {
  color: #881525;
  font-size: 40px;
  background: #f1f1f1;
  border-radius: 50%;
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0px 0px 13px 2px #0c0c0b42;
}

.glanceno {
  display: flex;
  justify-content: center;
  align-items: center;
}

.glancedesc p {
  font-size: 14px;
  text-align: center;
  line-height: 27px;
}
nav.navbar .dropdown-menu li:last-child {
  border: 0px solid #de725336;
}

nav.navbar .dropdown-menu li {
  border: 1px solid #de725336;
}
nav.navbar .dropdown-menu li a {
  font-family: 'Inter';
  font-size: 14px;
}
nav.navbar .dropdown-menu{
margin:0;
border:0px solid #ccc;
padding:0;

}
nav.navbar .dropdown-menu .dropdown-item.active  { 
  background-color: #881525;
}
.suggestionform {
  background: #f1f1f1;
  padding: 40px ;
  border-radius: 4px;
}
.popup {
  padding-right: 0 !important;
}
.popup .carousel-control-prev-icon { background: url('https://webapi.entab.info/api/image/HTSJ/public/Images/lefticn.png') no-repeat !important; }
.popup .carousel-control-next-icon { background: url('https://webapi.entab.info/api/image/HTSJ/public/Images/righticn.png') no-repeat !important; }
.popup .carousel-control-next, .popup .carousel-control-prev { width: auto; }
.popup .carousel-control-next { right: -15px; }
.popup .modal-dialog { min-width:40%; transform: translateY(-100px); }
.carousel-item .modal-header { background: #0a4685; padding: 13px 0 0 }
.popup .modal-dialog img { width: 100%; object-fit: contain; }
.carousel-item .modal-body h1 {padding-bottom: 0; text-align: center; font-size: 17px; color: #000; overflow: hidden; font-weight: 600; text-transform: capitalize; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; margin-bottom: 10px; line-height: 28px; }
.carousel-item .modal-dialog .modal-header .btn-close { color: #fff; padding-right: 31px }
.carousel-item .modalcard .card-body { padding: 10px }
.carousel-item .modalcard .card-body p { display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; line-height: 35px; text-align: center; padding-top: 10px; line-height: 26px; }
.carousel-item .modalcard { border: none }
.popup .modal-content { padding: 0 0 10px }
.carousel-item button.btn-close:focus { box-shadow: none }
.carousel-item a.btn.btn-primary.btn-sm { border-radius: 6px;
  box-shadow: -2px 14px 11px -9px #0000008a; 
  transition: .5s; background: #8c0000; border: #8c0000; margin: 0 10px; color: #fff!important ;     padding: 10px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;}
.carousel-item a.btn.btn-primary.btn-sm:focus { box-shadow: none; border: 1px solid #8c0000; }
.carousel-item a.btn.btn-primary.btn-sm:hover {
  background: linear-gradient(0deg,#8c0000,#f56c6c);
}
.carousel-item button.btn-close {position: absolute;z-index: 999;background-color: #8c0000;right: 0;}
.carousel-item .btn-close { background: url('https://webapi.entab.info/api/image/HTSJ/public/Images/closeicn.png') no-repeat; opacity: 1; padding: 10px; background-position: center; border-radius: 0; }
.modal-bottom { display: flex; flex-direction: row; justify-content: center; align-items: center; margin-top: 30px; }
.galleryimgbox img {
  height: 280px;
  width: 100%;
}

span.bounce1 img { width: 22px; }
.OnlineR a { color: #fff; }
a.parantlogo { padding: 2px 10px; }
.OnlineR { position: fixed; right: 0; z-index: 99; text-align: center; color: #fff; top: 40%; font-family: Open Sans, sans-serif; }
@keyframes bounce1 { 
  0% {
    background-color: #881525;
}

33% {
    background-color: #ecb30b;
}
66% {
    background-color: #881525;
}
100% {
    background-color: #ecb30b;
}
}
.bounce1 { border-radius: 25px 0px 0px 25px; color: #fff; font-size: 14px; font-weight: 700; text-align: center; animation: bounce1 1s infinite; padding: 10px 12px; }
.socialMedia img {
  width: 33px;
}
.attch {
  position: absolute;
  top: 0;
  left: 0;
  background: #1b4e8c;
  color: #fff;
  padding: 3px 5px;
  border-radius: 10px 0px 10px 0px;
}
.teachertable table td {
  border: 1px solid #ccc;
}
.teachertable  td.tabletr {
  background: #d5586926;
  color: #000;
  text-align: center;
}

.teachertable table tr:nth-child(odd) {
  background: #f1f1f1ab;
}

form.careerform-feilds >div {
  width: 49%;
  margin-right: 10px;
  margin-bottom:10px;
  margin-top: 0;
}
form.careerform-feilds >div label {
  margin-top: 5px;
  margin-bottom: 5px;
}
form.careerform-feilds {
  display: flex;
  
  background: #f1f1f1;
  padding: 30px;
  box-shadow: 0px 0px 8px 1px #ccccccc9;
  border-radius: 3px;
  flex-wrap: wrap;
}
 
.form-heading h6 {
  color: #000;
}
.quick-link ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 200px;
  margin-bottom: 0;
  padding: 0;
  width: 35%;
}
.app-cont {
  width: 65%; 
}
.mobileappimg img {
  margin-bottom: 20px;
  margin-top: 20px;
  width: 310px;
}
.guideheading i {
  font-size: 6px;
  position: relative;
  top: -3px;
}
p.link {
  background: #fff;
  border-radius: 5px;
  font-weight: 700;
  margin: 10px 0px;  
  padding: 5px 10px;
  width: -webkit-max-content;
  width: max-content;
}
.app-cont img.img-fluid {
  padding: 0 7px 0 0;
  width: 100px;
}

.quick-link { 
  display: flex;
}
/* Inner Page */ 
@media(max-width: 1600px){
  .quick-link ul li { 
    font-size: 13px;
}
.address span {
  font-size: 13px;
}
  .padded-sec {
    padding-left: 70px !important;
    padding-right: 70px !important;
}
.abt-content { 
  padding: 30px; 
  width: 90%;
} 
.toppertaj img{
  width: 100px;
  height: 100px;
}
}
@media(max-width: 1400px){
  .quick-link ul li { 
    font-size: 12px;
}
.address span {
  font-size: 12px;
}
  .ftr-logo img {
    width: 100%;
  }
  .footer h3 {
    font-size: 20px;
    line-height: 33px;
}
  .form-group { 
    width: 48%;
    margin-right: 13px;
}
  .highlights, nav.navbar { 
    width: 100%; 
}
.about-news {
  width: 90%; 
  padding: 20px 20px 0; 
}
nav.navbar ul.navbar-nav li{
  font-size: 18px;
}
.title h2 {
  font-size: 25px;
}
.abt_abs.abs-img4 img {
  width: 130px;
}
  .padded-sec{
    padding-left: 30px !important;
    padding-right: 30px !important 
  }  
  .message-container .messagebox {
    width: 76%; 
    background-size: 100% 100%;
    padding: 30px;
    margin-right: 20px;
}
.padded-sec {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.aboutmidsec .button{
  width: 15%;
}
.aboutmidsec p {
  margin-bottom: 0;
  font-size: 14px;
}
}
@media(max-width: 1200px){
  .ftr-logo img {
    width: 70%;
    margin-left: 0;
}
.ftr-logo > div {
  width: 100%;
  margin-left: auto;
}
  .blogboximg { 
    width: 30%;
  }
  .admissionbox {
    width: calc(50% - 20px);

  }
  .newsboximg {
    width: 100%;
  }
  .newsboxdesc {
    padding-left: 10px;
    width: 100%;
}
  .formboxright {
    width: 60%;
  }
  .form-group {
    width: 47%;
  }
  .formboxleft {
    width: 39%;
  }
  .aboutmidsec .phone{
    display: none;
  }
  .aboutmidsec .button {
    width: 30%;
    margin: 5px;
}
  .tabs {
    flex-wrap: wrap;
}

.tab {
    width: 32%;
}
  .aboutmidsec {
    flex-wrap: wrap;
}

 
  .highlights { 
    padding-top: 5px;
}
  .footer { 
    background-size: auto 100%;
    padding: 100px 80px 10px; 
}
.footer .ftrShape1{
  display: none;
}
 
.header .logo {
    width: 100%;
}

.header {
    flex-wrap: wrap;
    height: auto;
}

.header .logo img {
    margin: auto;
    display: block;
}

.header-nav {
    width: 100%;
}

.highlights {
    width: 100%;
}

nav.navbar {
    width: 100%;
    margin: 0;
} 
}
@media(max-width: 991px){
  div#main_nav ul { 
    padding: 0px 15px;
  }
  nav.navbar { 
    padding: 0px;
  }
  .blogboximg {
    width: 100%;
    margin: 20px auto 0px auto;
    display: block;
    text-align: center;
}
  .blogbox {
    flex-wrap: wrap;
  }
  .blogboximg {
    width: 100%;
}
  .admissionbox {
    width:  100%;

  }
  .glancediv {
    width: calc(50% - 30px);
}
  .form-group {
    width: 48%;
}
  .formboxright {
    width: 100%;
}
  .formboxleft {
    width: 100%;
}
  .classSection { 
    background-size: auto 50%;
}
  .aboutmidsec .button {
    width: 31%;
    margin: 5px;
}
  .about-bg { 
    background-size: 100% 50%; 
}
  .breadcrumb h2 { 
    max-width: 100%; 
}
  .tooper-birthday .slick-slider {
    margin-bottom: 40px;
}
.about-news { 
  margin-top: 50px;
  margin-bottom: 60px;
}
.event { 
  padding: 50px 0; 
}
button.navbar-toggler {
  float: right;
  display: block;
  margin-left: auto;
  background: #fff;
}

nav.navbar {
  background: transparent;
}

div#main_nav {
  background: #fff;
  text-align: left;
}

div#main_nav ul {
  align-items: flex-start; 
  position: absolute;
  background: #fff;
  border-radius: 15px;
}

div#main_nav ul li {
  padding: 5px 0;
  border-bottom: 1px dashed #ddd;
  width: 100%;
}
.calendar-wrapper, .message-container{
  flex-wrap: wrap;
}
.calendar-wrapper>div, .calendar-wrapper>div:last-child , .message-container> div{
  width: 100% !important;
  margin-bottom: 20px;
}
.message-container .messageimg{
  margin-top: 20px;
}
}

@media(max-width: 767px){
  form.careerform-feilds >div {
    width: 100%;
  }
  .glancebox {
    flex-wrap: wrap;
  }
  .toppertaj{ 
    width:60%; 
  }
  .message-container .messageimg  img { 
    margin-left: 0;
  }
  ul.list li {
    width: 100%;
  }
  .form-group {
    width: 100%;
}
  .newspgh {
    padding-left: 15px;
  }
  .newsboxdesc {
    width: 100%;
}
  .newsboximg {
    width: 100%;
  }
  .left-img { 
    margin-left: 0;
  }
  .aboutmidsec .button {
    width: 48%; 
}
  .event:before, .event:after { 
    width: 240px;
    height: 240px; 
    left: -203px; 
}
.message-container{
  position: relative;
}
.calender:after, .calender:before{  
  width: 240px;
  height: 240px; 
}
.padded-sec {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
}
@media(max-width: 600px){
  .glancediv {
    width:  100%;
}

.aboutmidsec {
  align-items: center;
  justify-content: center;
  float: unset;
  margin-top: 0;
}
  .about-img {
    padding: 10px 0 10px; 
}
.overBox{
  display: none;
}
.classSection{
  margin: 0;
}
  .calender_block { 
    padding: 10px; 
}
#calendar-table {
  text-align: center;
  font-size: 12px;
}
  .center-img{
    width: 100%;
  }
  .abt_abs{
    display: none;
  }
  .tab {
    width: 48%;
}
.calendar-wrapper { 
  width: 100%; 
}
.tabs-content {
  padding: 20px 10px; 
}
.aboutmidsec .button {
  width: 100%; 
}
}
@media(max-width: 400px){
  .tab {
    width: 100%;
}
}